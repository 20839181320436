.contenedor{
  width: 100%;
  height: 74px;
  background-color: var(--color-azul-fuerte);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo{
  width: 218px;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.logo_imagen{
  width: 95.44px;
  height: 42px;
  background-image: url('../../assets/logo_bits_header.png');
}

.usuario{
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.usuario__imagen{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.usuario__nombre{
  font-family: var(--font-family);
  color: var(--color-blanco);
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.028em;
}

.logoPrueba{
  width: 100px;
  height: 100px;
  background-color: aqua;
}