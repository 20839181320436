.tabla{
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #e9ebf1;
}

.columnaTabla{
  background-color: var(--color-gris-bajo-dos);
  width: 11.1%;
  text-align: center;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
  color: var(--color-negro-titulos)
}

.columnaTablaDos{
  background-color: var(--color-gris-bajo-dos);
  width: 10%;
  text-align: center;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
  color: var(--color-negro-titulos)
}

.columnaDatos{
  text-align: center;
}

.columnaTexto{
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: var(--color-negro-titulos);
  opacity: 0.8;
  text-align: center;
}

.lineaTitulo{
  height: 40px;
  border-bottom: 1px solid #e9ebf1;

}

.boton{
  width: 110px;
  gap: 5px;
  background-color: var(--color-amarillo-base);
  border: none;
  cursor: pointer;
  height: 24px;
  border-radius: 4px;
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: var(--color-azul-texto-negritas);
}
.botonDesactivado{
  width: 110px;
  gap: 5px;
  background-color: var(--color-amarillo-base);
  border: none;
  cursor: no-drop;
  height: 24px;
  border-radius: 4px;
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: var(--color-azul-texto-negritas);
  opacity:0.2;
}

.textoBoton{
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #093C5D;
}

.botonLink{
  width: 100%;
  height: 100%;
  color: var(--color-azul-texto-negritas);
  text-decoration: none;
}

.enviarArchivo{
  background-color: antiquewhite;
}

input[type="file"] {
  display: none;
}

.botonsubirArchivos{
  background-color: #ffffff;
  border-radius: 4px;
  width: 110px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #093C5D;
  cursor: pointer;
  margin: 0 auto;
}

.botonsubirArchivosDesctivado{
  background-color: #ffffff;
  border: 0.8px solid #C7CCDC;
  border-radius: 4px;
  width: 110px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #093C5D;
  cursor: no-drop;
  opacity: 0.2;
}

.botonesEstado{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
  height: 40px;
}

.botonEstado{
  width: 90px;
  font-family: var(--font-family);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #093C5D;
  cursor: pointer;
  height: 24px;
}

.botonAceptado{
  background-color:#7aff98;
  height: 24px;
  border: none;
  color: #093C5D;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  font-family: var(--font-family);
  border-radius: 4px;
}

.botonRechazado{
  background-color:#ff7a7a;
  height: 24px;
  border: none;
  font-family: var(--font-family);
  color: #093C5D;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  border-radius: 4px;
  margin-right: 10px;
}

.lineaAceptado{
  background-color: #daffe3;
}

.lineaRechazado{
  background-color: #fdc1c1;
}

.iconoBoton{
  margin-right: 5px;
}

.contenedorTablas{
  width: 95%;
  height: auto;
  background-color: var(--color-blanco);
  /*margin-left: 46px;*/
  /*margin-top: 21px;*/
  border-radius: 12px;
  padding-top: 18px;
  padding-bottom: 20px;
  overflow-x: auto;
}


.tituloTabla{
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.66px;
  color: #323232;
  margin-left: 20px;
}

.subtituloTabla{
  font-family: 'Roboto';
  font-size: 18px;
  letter-spacing: 0.66px;
  color: #323232;
  margin-left: 20px;
  margin-bottom: 0px;
}

.inputFiltrado{
  margin-left: 5px;
  width: 415px;
  border: none;
  font-family: 'Roboto';
  color: #303030;
}

.inputFiltrado:focus{
  border: none !important;
  outline: none;
}

.inputFiltrado::placeholder{
  font-family: 'Roboto',sans-serif;
  font-style: italic;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #303030;
  opacity: 0.6;
}

.iconoFiltro{
  background-image: url('/src/assets/iconoFIltro.svg');
  width: 18.11px;
  height: 18.11px;
}

.contenedorInput{
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 454.5px;
  height: 32px;
  margin-left: 20px;
  border: 1px solid #C7CCDC;
  border-radius: 8px;
}

.contenedorTablaInterno{
  width: 100%;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  height: auto;
}

/*ICONOS*/

.contenedorBoton{
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.iconoDocumento{
  background-image: url('../../assets/icono_documento.svg');
  width: 22px;
  height: 28.55px;
}

.iconoCertificado{
  background-image: url('../../assets/icono_certificado.svg');
  width: 22px;
  height: 28px;
}

.iconoLlave{
  background-image: url('../../assets/icono_llave.svg');
  width: 24px;
  height: 29px;
}

.iconoNoProcesado{
  background-image: url('../../assets/icono_noprocesado.svg');
  height: 25px;
  width: 24px;
}

.iconoEnviar{
  background-image: url('../../assets/icono_enviar.svg');
  height: 23px;
  width: 24px;
}

.iconoEnviarDesactivado{
  background-image: url('../../assets/icono_enviar.svg');
  height: 23px;
  width: 24px;
  opacity: 0.2;
}

.iconoSubirArchivo{
  background-image: url('../../assets/icono_subirarchivo.svg');
  /* url('/src/assets/svg/icono_subir_archivo_empresa.svg'); */
  height: 29px;
  width: 24px;
}

.iconoSubirRespuesta{
  background-image: url('../../assets//icono_subirarchivo.svg');
  height: 28px;
  width: 22px;
}

.iconoSubirRespuestaDesactivado{
  background-image: url('../../assets/icono_subirarchivo.svg');
  height: 28px;
  width: 22px;
  opacity: 0.2;
}

.iconoVerRespuesta{
  background-image: url('../../assets/icono_verrespuesta.svg');
  height: 18px;
  width: 26px;
}

.iconoVer{
  background-image: url('../../assets/icono_ver.svg');
  height: 18px;
  width: 26px;
}

.iconoFacturacionPagos{
  background-image: url('../../assets/icono_facturacion_pagos.svg');
  height: 27px;
  width: 30px;
}

.iconoAceptado{
  background-image: url('../../assets//icono_aceptado.svg');
  width: 25px;
  height: 24px;
  cursor: pointer;
}

.iconoAceptadoDesactivado{
  background-image: url('../../assets//icono_aceptado.svg');
  width: 25px;
  height: 24px;
  opacity: 0.2;
  cursor: pointer;

}

.iconoDescargarDocumento{
  background-image: url('../../assets/icono_descarga_documento.svg');
  width: 13.18px;
  height: 16.01px;
  margin-right: 6px;
}

.iconoRechazado{
  background-image: url('../../assets/icono_rechazado.svg');
  width: 24px;
  height: 25px;
  cursor: pointer;

}

.iconoRechazadoDesactivado{
  background-image: url('../../assets/icono_rechazado.svg');
  width: 24px;
  height: 25px;
  opacity: 0.2;
  cursor: pointer;

}

.iconoRegresar{
  background-image: url('../../assets/icono_regresar.svg');
  width: 25px;
  height: 25px;
}
/*PAGINACION*/

.contenedorPaginacion{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 18px;
  padding-top: 18px;
  height: 20px;
}

.iconoPaginacion{
  background-image: url('/src/assets/icono_paginacion_flecha.svg');
  width: 7px;
  height:4px;
  margin-left: 7.77px;
  margin-right: 17.23px;
}

.iconoPaginacionSiguienteActivo{
  background-image: url('../../assets/icono_siguiente_activo.svg');
  width: 16px;
  height: 16px;
}

.iconoPaginacionSiguienteInactivo{
  background-image: url('../../assets/icono_siguiente_inactivo.svg');
  width: 16px;
  height: 16px;
}

.iconoPaginacionAnteriorActivo{
  background-image: url('../../assets/icono_siguiente_activo.svg');
  width: 16px;
  height: 16px;
  transform: rotate(180deg);
}

.iconoPaginacionAnteriorInactivo{
  background-image: url('../../assets/icono_siguiente_inactivo.svg');
  width: 16px;
  height: 16px;
  transform: rotate(180deg);
}

.columnaDosPaginacion{
  display: flex;
  justify-content: center;
}

.columnaInternaPaginacionDos{
  display: flex;
  align-items: center;
}

.columnaUnoPaginacion{
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 13px;
  color: #303030;
  opacity: 0.8;
}

.textoPaginacion{
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 13px;
  color: #303030;
  opacity: 0.8;
}

.columnaInternaPaginacion{
  display: flex;
  align-items: center;
}

.columnaBotonesPaginacion{
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 5px;
}

.contenedorListaElementos{
  width: 20px;
  height: auto;
  border-radius: 4px;
  margin-left: -15px;
}


.listaElementos{
  list-style: none;
  padding: 0;
  text-align: center;
}

.listaElementos > li{
  border: 1px solid #C7CCDC;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 13px;
  color: #303030;
  opacity: 0.8;
}

.listaElementos > li:hover{
  background-color: #C7CCDC;
  border: 1px solid #C7CCDC;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 13px;
  color: #303030;
  opacity: 0.8;
}

.espacioTabla{
  height: auto;
  padding-bottom:10px;
}

.loading{
  background-image: url('../../assets/loading.gif');
  width:100px;
  height: 100px;
  background-size: cover;
}

.contenedorLoading{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Descarga de documentos*/
.botonDescargaDocumento{
  background-color: var(--color-amarillo-base);
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  border-radius: 6px;
  cursor: pointer;
}

.textobotonDescargaDocumento{
  text-decoration: none;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #093C5D;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .contenedorTablas{
    min-width: 1200px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .contenedorTablas{
    min-width: 1200px;
  }
}

/* Facturacion y pagos */

.fp_contenedorSelect{
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 20px;

}

.fp_select{
  width: 200px;
  height: 30px;
  border-radius: 6px;
  border: 1px solid #C7CCDC;
  padding-left: 10px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #303030;
  opacity: 0.8;  
}

.fp_tituloTotal{
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #303030;
  opacity: 0.8;
  padding-left: 20px;
}

.fp_contenedorTotal{
  display: flex;
  align-items: center;
  border: 1px solid #C7CCDC;
  height: 28px;
  border-radius: 6px;
  width: 50px;
  margin-left: 10px;
  font-size: 14px;
  font-family: 'Roboto';
  color: #303030;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700
}

.fp_inputMonto{
  width: 100px;
  height: 30px;
  border: 1px solid #C7CCDC;
  border-radius: 6px;
  padding-left: 10px;
}

.fp_inputUUID{
  width: 440px;
  height: 30px;
  border: 1px solid #C7CCDC;
  border-radius: 6px;
  padding-left: 10px;
  margin-right: 10px;
}

.fp_archivoDesactivado{
  margin-left: 45px;
  margin-right: 43px;
}


.fp_botonDescargarArchivo{
  width: 112px;
  height: 20px;
  background-color: var(--color-blanco);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}

.fp_textoEliminarArchivo{
  color: #c02f2f;
  font-family: 'Roboto';
  font-size: 11px;
  cursor: pointer;
}

.fp_botonGuardar{
  width: 80px;
  height: 32px;
  background-color: var(--color-amarillo-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 10px;
  border: none;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: #093C5D;
}

.contenedorInputDos{
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 220px;
  height: 32px;
  margin-left: 20px;
  border: 1px solid #C7CCDC;
  border-radius: 8px;
}

.inputFiltradoDos{
  margin-left: 5px;
  width: 200px;
  border: none;
  font-family: 'Roboto';
  color: #303030;
}

.inputFiltradoDos:focus{
  border: none !important;
  outline: none;
}

.inputFiltradoDos::placeholder{
  font-family: 'Roboto',sans-serif;
  font-style: italic;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #303030;
  opacity: 0.6;
}

.fp_contenedorTotalExterno{
  display: flex;
  width: 300px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.inputFiltradoFecha{
  margin-left: 5px;
  width: 200px;
  border: none;
  font-family: 'Roboto';
  color: #303030;
}

.inputFiltradoFecha:focus{
  border: none !important;
  outline: none;
}

.inputFiltradoFecha::placeholder{
  font-family: 'Roboto',sans-serif;
  font-style: italic;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #303030;
  opacity: 0.6;
}
/*Boton de regresar*/

.pd_contenedorBarraEstado{
  height: auto;
  width: 96%;
  margin-left: 29px;
  margin-top: 67.19px;
}

.pd_barraEstado{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pd_contenedorRegreso{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #222222;
  opacity: 0.8;
  cursor: pointer;
}

.pd_botonRegreso{
  background-image: url('/src/assets/svg/icono_regresar_dos.svg');
  height: 15.18px;
  width: 15.58px;
  margin-right: 22px;
}

.pd_botonSalir{
  width: 110px;
  height: 34px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #093c5d;
  background-color: #ffffff;
  border: solid 0.8px #c7ccdc;
  border-radius: 4px;
  cursor: pointer;
  
}

.textoRegresar{
  text-decoration: none;
  color: #222222;
  opacity: 0.8;
  display: flex;
}

.botonSalir{
  width: 110px;
  border: solid 0.8px #c7ccdc;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  color: #093C5D;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

/*Boton estatus Facturacion y pagos*/

.fp_contenedorPagado{
  width: 113px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconoPagado{
  background-image: url('/src/assets/svg/icono-pagado.svg');
  width: 21px;
  height: 22px;
  margin-right: 6.5px;
  filter: opacity(0.4) drop-shadow(0,0,0 red);
}

.textoBotonPagado{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #11CA73;
}

.fp_contenedorPagar{
  width: 113px;
  height: 24px;
  background-color: #fabb00;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 13px;
  color: #093c5d;
}

.iconoAgegarPago{
  background-image: url('/src/assets/svg/icono-money.svg');
  width: 20px;
  height: 17px;
  margin-right: 6.5px;
  filter: opacity(0.4) drop-shadow(0,0,0 red);
}

.fp_contenedorReintentar{
  width: 120px;
  height: 30px;
  font-family: 'Roboto';
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.fp_contenedorInternoReintentar{
  display: flex;
  justify-content: center;
  align-items: center;
}

.pd_iconoError{
  background-image: url('/src/assets/svg/icono_error_dos.svg');
  width: 8.97px;
  height: 8px;
  margin-right: 4px;
}

.fp_textoError{
  color: #E0544B;
}

.fp_textoMensajeDispersion{
  color: #E0544B;
  font-family: 'Roboto';
  font-size: 10px;
}

