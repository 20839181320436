.linkActivo{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.44px;
    color: var(--color-azul-texto-negritas);
    text-decoration: none;
}
  
.linkInactivo{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.44px;
    color: var(--color-azul-texto-negritas);
    opacity: 0.6;
    text-decoration: none;
}
