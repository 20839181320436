.contenedorRutas{
  margin-top: 20px;
  margin-left: 20px;
  margin-left: 46px;
  display: flex;
  align-items: center;
  gap: 40px;
}

.lineaUno{
  margin-left: 46px;
  height: 4px;
  width: 385px;
  background-color: var(--color-gris-bajo);
  display: flex;
  align-items: center;
  margin-top: 10px;
  border-radius: 2px;
}

.lineaInterna{
  height: 4px;
  background-color:var(--color-azul-link);
  height: 4px;
  width: 200px;
  transition: all 0.3s ease;
  border-radius: 2px;
}

.lineaInternaUno{
  margin-right: 200px;
  margin-left: 0px;
}

.lineaInternaDos{
  margin-left: 200px;
  margin-right: 0px;
}