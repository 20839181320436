.ToolBarContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
}

@media (max-width: 1024px) {
    .ToolBarContainer {
        flex-direction: column;
        min-height: 150px;
    }
}