@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root{
  --font-family: 'Roboto', sans-serif;
  --font-family-Rubik: 'Rubik', sans-serif;
  --color-azul-fuerte: #022338;
  --color-blanco: #ffffff;
  --color-azul-texto-negritas:#093C5D;
  --color-gris-bajo: #C7CCDC;
  --color-gris-bajo-dos: #E9EBF1;
  --color-azul-link: #2186C6;
  --color-negro-titulos: #303030;
  --color-amarillo-base: #FABB00;
}

html, body{
  height:100%;
  margin: 0;
}


.swal2-confirm{
  /* display: inline-block !important; */
  background-color: #FABB00 !important;
  box-shadow: none !important;
  color: #093C5D !important;
  font-family: roboto !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  margin-top: 25px;
}