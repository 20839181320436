.loading{
  background-image: url('../../assets/loading.gif');
  width:100px;
  height: 100px;
  background-size: cover;
}

.contenedorLoading{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
