.tituloAlerta{
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
  color: #093C5D;
  margin-top: 20px;
  margin-bottom: 19.5px;
}

.contenedortexto{
  width: 100%;
  height: auto;
  text-align: justify;
}

.contenedortextoDos{
  width: 100%;
  height: auto;
  text-align: center;
}


.textoAlerta{
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #222222;
  opacity: 0.8;
  letter-spacing: 0.005em;
}

.textoresaltado{
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 15px;
  line-height: 27px;
  color: #222222;
  opacity: 0.8;
}

.contenedorIcono{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45.8px;
}

.iconoAlerta{
  background-image: url('../assets/modal_atencion.svg');
  width: 93px;
  height: 93px;
}

.iconoListo{
  background-image: url('../assets/modal_listo.svg');
  width: 79px;
  height: 79px;
}


.contenedorComentario{
  margin-top: 15px;
}

.tituloAlertaDos{
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: #093C5D;
  margin-top: 17.77px;
  margin-bottom: 7px;
}

.textoAlertaDos{
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #222222;
  opacity: 0.8;
}