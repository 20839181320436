.contenedor{
  background-image: url('../../assets/svg/icono_fondo_admin.svg');
  background-size: cover;                     
  background-repeat: no-repeat;
  background-position: center center; 
  height: 100vh;
  object-fit: fill !important; 
  overflow-y: auto;
  border-left: 1px #022338 solid;
}

.contenedor__formulario{
  margin-left: 94.2px;
  margin-top: calc(5%);
  margin-bottom: calc(3%);
}

.formulario{
  height: 744.67px;
  width: 569.6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 30px;
}

.formulario__titulo{
  font-family: var(--font-family-Rubik);
  font-weight: 700;
  line-height: 36px;
  font-size: 30px;
  color: var(--color-azul-texto-negritas);
  margin-top: 72.03px;
  margin-bottom: 49.75px;
}

.formulario__boton{
  width: 400px;
  height: 56.86px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 2px 2px #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.56px;
  color: #323232;
  font-family: var(--font-family);
  cursor: pointer;
}

.formulario__segundoBoton{
  margin-top: 23.43px;
}

.formulario__icono_google{
  background-image: url('../../assets/icono-google.svg');
  width: 26px;
  height: 26px;
  margin-right: 34.1px;
}

.formulario__icono_Microsoft{
  background-image: url('../../assets/icono_microsoft.svg');
  width: 24.5px;
  height: 24.5px;
  margin-right: 34.1px;
}


.formulario__flex{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.56px;
  color: #323232;
  gap: 6px;
  margin-top: 43.95px;
  margin-bottom: 43.95px;
}

.formulario__linea{
  height: 1px;
  width: 188.03px;
  background-color: #000000;
  opacity: 0.2;
}

.formulario__input{
  width: 386px;
  height: 42px;
  border: 0.8px solid #C7CCDC;
  border-radius: 4px;
  padding-left: 14px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #222222;
}

.primer__Input{
  margin-bottom: 22px;
}

.formulario__olvidaste{
  font-family: var(--font-family);
  color: #222222;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  opacity: 0.6;
  cursor: pointer;
  margin-top: 14.5px;
}

.boton{
  margin-top: 24.91px;
  height: 40px;
  width: 153.13px;
  border-radius: 10px;
  border: none;
  background-color: var(--color-amarillo-base);
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #093c5d;
  cursor: pointer;
}