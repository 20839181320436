.PageTitle {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: var(--color-azul-texto-negritas);
    margin-left: 46px;
    margin-top: 41px;
}
