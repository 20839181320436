.mainContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
    background-color: #f5f6f9;
    padding-bottom: 30px;
    min-height: calc(100vh - 74px);
}

@media only screen and (max-width: 600px) {
    .mainContainer{
        min-width: 1300px;
    }
}
  
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .mainContainer{
        min-width: 1300px;
    }
}