.contenedorPrincipal{
  width: 96%;
  height: auto;
  background-color: #ffffff;
  border-radius: 12px;
}

.contenedorHeader{
  width: 100%;
  height: auto;
  display: flex;
}

.contenedorFiltro{
  width: 50%;
  display: flex;
  align-items: center;
}

.contenedorBotones{
  width: 50%; 
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
}

.contenedorTabla{
  width: 100px;
}

.botonDescargaDocumento{
  background-color: var(--color-amarillo-base);
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  border-radius: 6px;
  cursor: pointer;
}

.contenedorInput{
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 454.5px;
  height: 32px;
  margin-left: 20px;
  border: 1px solid #c7ccdc;
  border-radius: 8px;
}

.inputFiltrado{
  margin-left: 5px;
  width: 415px;
  border: none;
  font-family: 'Roboto';
  color: #303030;
}

.inputFiltrado:focus{
  border: none !important;
  outline: none;
}

.inputFiltrado::placeholder{
  font-family: 'Roboto', sans-serif;
  font-style: italic;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #303030;
  opacity: 0.6;
}

.iconoFiltro{
  background-image: url('/src/assets/iconoFIltro.svg');
  width: 18.11px;
  height: 18.11px;
}

.contenedorTablaInterno{
  width: 100%;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  height: auto;
}

.espacioTabla{
  height: auto;
  padding-bottom:10px;
}

.tabla{
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #e9ebf1;
}

.columnaTabla{
  background-color: var(--color-gris-bajo-dos);
  width: 11.1%;
  text-align: center;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
  color: var(--color-negro-titulos);
  height: 32px;
}

.columnaTablaDos{
  background-color: var(--color-gris-bajo-dos);
  width: 10%;
  text-align: center;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
  color: var(--color-negro-titulos)
}

.columnaDatos{
  text-align: center;
}

.columnaTexto{
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: var(--color-negro-titulos);
  opacity: 0.8;
  text-align: center;
  height: 48px;
  border-bottom: 1px solid #C7CCDC; 
}

.columnaBotonesPaginacion{
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 5px;
}

.iconoPaginacionAnteriorActivo{
  background-image: url('../../assets/icono_siguiente_activo.svg');
  width: 16px;
  height: 16px;
  transform: rotate(180deg);
}

.textoPaginacion{
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 13px;
  color: #303030;
  opacity: 0.8;
}

.iconoPaginacion{
  background-image: url('/src/assets/icono_paginacion_flecha.svg');
  width: 7px;
  height:4px;
  margin-left: 7.77px;
  margin-right: 17.23px;
}

.contenedorListaElementos{
  width: 20px;
  height: auto;
  border-radius: 4px;
  margin-left: -15px;
}

.listaElementos{
  list-style: none;
  padding: 0;
  text-align: center;
}

.listaElementos > li{
  border: 1px solid #C7CCDC;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 13px;
  color: #303030;
  opacity: 0.8;
}

.listaElementos > li:hover{
  background-color: #C7CCDC;
  border: 1px solid #C7CCDC;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 13px;
  color: #303030;
  opacity: 0.8;
}

.contenedorPaginacion{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 18px;
  padding-top: 18px;
  height: 20px;
}

.columnaUnoPaginacion{
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 13px;
  color: #303030;
  opacity: 0.8;
}

.columnaDosPaginacion{
  display: flex;
  justify-content: center;
}

.iconoPaginacionSiguienteActivo{
  background-image: url('../../assets/icono_siguiente_activo.svg');
  width: 16px;
  height: 16px;
}

.pd_columnaTitulos{
  text-align: start;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #303030;
}

.pd_primerColumnaTitulo{
  display: flex;
  height: 32px;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
}

.checkboxitem {
  display: inline-block;
  position: relative;
  text-align: left;
}

.checkboxitem input[type="checkbox"]:checked + label:after {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 3px;
  left: 3px;
  content: " ";
  display: block;
  background: #fabb00;
}

.checkboxitem label:before {
  content: " ";
  display: inline-block;
  position: relative;
  margin: 0 5px 0 0;
  width: 14px;
  height: 14px;
  border: 1px solid #c7ccdc;
  background-color: transparent;
}

.checkboxitem input[type="checkbox"] {
  display: none;
}

.contenedorInternoBotones{
  border: solid 1px red;
}

.tablaArchivos{
  width: 100%;
  border-collapse: collapse;
}

.titulosTablaArchivos{
  background-color: var(--color-gris-bajo-dos);
  width: 11.1%;
  text-align: center;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
  color: var(--color-negro-titulos);
  height: 32px;
}

.columnaInternaArchivosUno{
  width: 20%;
  text-align: center;
}

.columnaInternaArchivos{
  width:50%;
}

.textAreaCorreos{
  width: 80%;
  border: 1px solid #c7ccdc;
  height: 50px;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #303030;
  font-size: 14px;
  margin: 5px auto 0px;
  display: block;
}

.botonEnviar{
  width: 106px;
  height: 32px;
  background-color: #fabb00;
  box-shadow: 0px 2px 8px rgba(250, 187,0,0.4);
  border-radius: 6px;
}